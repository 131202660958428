import React, {useState, useEffect} from 'react';

import { ethers } from 'ethers';
import { useAccount } from 'wagmi';

import LionStaking from '../artifacts/contracts/LionStaking.sol/LionStaking.json';
import LionToken from '../artifacts/contracts/LionToken.sol/LionToken.json';
import LionMaxx from '../artifacts/contracts/LionMaxx.sol/LionMAXX.json';
import Logo from '../Images/logo1.png';
import { Box, Heading, Button, Card, CardBody, Image, CardHeader, Flex, Grid, Text } from '@chakra-ui/react';
import UnstakedNFT from 'Components/UnstakedNFT';
import '../Styles/Home.css'

import Popup from 'reactjs-popup';
import LionNft from '../artifacts/contracts/LionNFT.sol/LionNFT.json';


import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});





const tokenContractAddress = '0x25490a833a22050dEaE49647d0c264e1960FF8E0';//maxx token
const stakingContractAddress = '0x2D653c7256e5977B235421fD267E5623B8Cf8fcd';//maxx stake


const Staking = () => {

    const { address, isConnecting, isDisconnected} = useAccount();
    const [ownerTokenStakedId, setOwnerTokenStakedId] = useState([]);
    const [rewardsAmount, setRewardsAmount] = useState(0);
    const [balance, setBalance] = useState(0);
    const [PoolData, setfetchpoolData] = useState(0);
    const [depositValuePWRD, setDepositValuePWRD] = useState();

    const [PWRDEarnedTotal, setPWRDearnedCount] = useState([])
   

  
    const [PWRDcalcTotal, setPWRDcalcCount] = useState([])


  
    const [PWRDunlock, setPWRDunlock] = useState()
    

 
    const [PWRDtokens, setPWRDtokens] = useState()
    const [PWRDpoolstaked, setPWRDpoolstaked] = useState()
    

   


   
    const [PWRD15, setPWRD15tokens] = useState()
    const [PWRD30, setPWRD30tokens] = useState()
    const [PWRDpoolstakers, setPWRDpoolstakers] = useState()
    const [calcPWRD15, setPWRDcalc15] = useState()
    const [calcPWRD, setPWRDcalc30] = useState()



   
    const [earningDayPWRD, setPWRDearndaytokens] = useState()
  
   

    
 


    const [currentAddress, setCurrentAddress] = useState('');
  
   

    
 


    useEffect(() => {
      // Your data fetching functions here
      fetchTokenData();
      fetchPWRDearnedData();
      fetchPWRDpoolstakedData();
      fetchPWRDpoolstakersData();
      fetchPWRDcalc30Data();
      fetchPWRDtokenData();
      fetchPWRDunlockData();
 
      fetchPWRDperdaystakeData();
    
      // Listen for chain changes
      window.ethereum.on('chainChanged', () => {
        updateData();
      });
    
      // Call the updateData function on page load
      updateData();
    }, []);
    
    const updateData = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
    
      setBalance(0);
      fetchTokenData(address);
      fetchPWRDearnedData(address);
      fetchPWRDpoolstakedData();
      fetchPWRDpoolstakersData();
      fetchPWRDcalc30Data(address);
      fetchPWRDtokenData(address);
      fetchPWRDunlockData(address);

   
      fetchPWRDperdaystakeData(address); 
    
      setCurrentAddress(address);
    }
    
    const handleDepositChangePWRD = (e) => {
      setDepositValuePWRD(e.target.value)
    }
    




    async function fetchPWRDpoolstakersData() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, provider);
        try {
          if(address) {
            const rum = await contract.PWRPoolStakers();
            let rumCount = await(rum);
            let rumCalc  = rumCount;
            let rumTotal = rumCalc.toString()
              
            setPWRDpoolstakers(rumTotal)
            setTimeout(fetchPWRDpoolstakersData, 10000 );
               
          }}
          catch (err) {
            console.log(err.message);
          }
        }
    }


    async function fetchPWRDperdaystakeData() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, provider);
        try {
          if(address) {
            const rum = await contract.stakerVaultPWRD(address);
            let rumCount = await(rum);
            
            let rumTotal = rumCount[5].toString();
            let Total = rumTotal

            if(Total == "0"){ 
              Total = "0 PWR"
            
              setPWRDearndaytokens(Total)
                }



                if(Total == "15"){ 
                  const provider = new ethers.providers.Web3Provider(window.ethereum);
                  const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, provider);
                  const rate = await contract.viewRewardsEst(address);
                  let rewardrate = await(rate);
                  let newreward = ((rewardrate) * 80) / 100;//80%
                  let stringrate = newreward.toString();
                  let totalRewards = stringrate / 1000000000000000000;
                  let fixedrate = totalRewards.toFixed(2);
                  let end = fixedrate.toString() + " " + "PWR"
                  setPWRDearndaytokens(end)
                  setTimeout(fetchPWRDperdaystakeData, 10000 );
                  console.log(end) }
              
                


                  if(Total == "30"){ 
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, provider);
                    const rate = await contract.viewRewardsEst(address);
                    let rewardrate = await(rate);
                    let newreward = rewardrate;
                    let stringrate = newreward.toString();
                    let totalRewards = stringrate / 1000000000000000000;
                    let fixedrate = totalRewards.toFixed(2);
                    let end = fixedrate.toString() + " " + "PWR"
                    setPWRDearndaytokens(end)
                    setTimeout(fetchPWRDperdaystakeData, 10000 );
                    console.log(end) }
              
           
          }}
          catch (err) {
            console.log(err.message);
          }
    }}
    




    async function fetchPWRDpoolstakedData() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, provider);
        try {
          if(address) {
            const rum = await contract.PWRPoolStaked();
            let rumCount = await(rum);
            let rumCalc  = (rumCount  / 1000000000000000000).toFixed(2);
            let rumTotal = rumCalc.toString()
              
            setPWRDpoolstaked(rumTotal)
            setTimeout(fetchPWRDpoolstakedData, 10000 );
                
          }}
          catch (err) {
            console.log(err.message);
          }
        }
    }




  


   

    async function fetchPWRDcalc30Data() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, provider);
        try {
          const Staker = await contract.stakerVaultPWRD(address);
          const warchest = await contract.PWRDstakingPaused();
          const expiry = await contract.PWRDPoolTimeRemain();
          let timer = await(expiry);
          let finaltime = timer.toString();
          let Staked = await(Staker);
          let isStaked = Staked[6].toString();
          let warchestCount = await(warchest);
          let warchestTotal = warchestCount.toString()
          if(address) {
            
            if(warchestTotal == "false"){

            const rum = await contract.viewPotentialDayReturn(address);
            let rumCount = await(rum);
            let timeramount = rumCount  / 1000000000000000000;
            let rumCalc  = ((rumCount  / 1000000000000000000) / finaltime).toFixed(2);
            let rumTotal = rumCalc.toString() +" " + "PWR"

            let half = (((timeramount / 2) * .80) / finaltime).toFixed(2);
            let fin = half.toString() +" " + "PWR"

              setPWRDcalc15(fin)
            setPWRDcalc30(rumTotal)
            console.log(fin)
            console.log(rumTotal)
            setTimeout(fetchPWRDcalc30Data, 10000 );
                }

              if(warchestTotal == "true"){
            
                let rumTotal = "Closed"
                setPWRDcalc15(rumTotal)
                  
                setPWRDcalc30(rumTotal)
                setTimeout(fetchPWRDcalc30Data, 10000 );
              }

             
          }}
          catch (err) {
            console.log(err.message);
          }
        }
    }

    

   


    async function fetchPWRDtokenData() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, provider);
        try {
          if(address) {
            const rum = await contract.stakerVaultPWRD(address);
            let rumCount = await(rum);
            let rumTotal = rumCount[0].toString() /1000000000000000000;
            let Total = rumTotal.toFixed(2)
              
              setPWRDtokens(Total)
              setTimeout(fetchPWRDtokenData, 10000 );
              
          }}
          catch (err) {
            console.log(err.message);
          }
        }
    }



   
//TIMELOCK ON PWRD
    async function fetchPWRDunlockData() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, provider);
        try {
          if(address) {
            const rum = await contract.stakerVaultPWRD(address);
            let rumCount = await(rum);
            let rumTotal = rumCount[2].toString()
              
              setPWRDunlock(rumTotal)
              setTimeout(fetchPWRDunlockData, 10000 );
               
          }}
          catch (err) {
            console.log(err.message);
          }
        }
    }


   




   


   

   

//CLAIM FUNCTIONS CALLED



async function ClaimPWRD() {
      
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, signer);
   
    
      let transaction = await contract.claimPWRDRewards();
      await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
      Toast.fire({icon: 'success',title: 'Successful App Updating'})
     
       
     
    }
  
    catch (err) {
      console.log(err.message);
      Toast.fire({icon: 'error',title: (err.message)})
    }
  
}





async function StakePWRD15(depositValuePWRD) {
  
  
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, signer);
 
    const TknAmt = ethers.utils.parseUnits(depositValuePWRD.toString(), 'ether');
    const NewAmt = TknAmt;
    
      let transaction = await contract.stakePWRD(NewAmt,15);
      await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
      Toast.fire({icon: 'success',title: 'Successful App Updating'})
    
     
    }
  
    catch (err) {
      console.log(err.message);
      Toast.fire({icon: 'error',title: (err.message)})
    }
  
}




async function approveTokens(depositValuePWRD) {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
  
    const newContract = new ethers.Contract(tokenContractAddress, LionToken.abi, signer);

    // Make sure depositValuePWRD is a BigNumber
    const depositValueBigNumber = ethers.BigNumber.from(depositValuePWRD);
    const amountToApprove = depositValueBigNumber.mul(105).div(100); // 1.05 times the deposit value

    const TknAmt = ethers.utils.parseUnits(amountToApprove.toString(), 'ether');

    // Get the current allowance
    const currentAllowance = await newContract.allowance(await signer.getAddress(), stakingContractAddress);

    // If the current allowance is less than the required amount, then we approve
    if (currentAllowance.lt(TknAmt)) {
      let transaction = await newContract.approve(stakingContractAddress, TknAmt);
      await transaction.wait(Toast.fire({icon: 'info', title: 'Pending Please Wait.'}));
      Toast.fire({icon: 'success', title: 'Successful App Updating'});
    } else {
      Toast.fire({icon: 'info', title: 'Approval not needed. Allowance is already sufficient.'});
    }
  } catch (err) {
    console.log(err.message);
    Toast.fire({icon: 'error', title: (err.message)});
  }
}



    async function StakePWRD30(depositValuePWRD) {
      
      
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, signer);
       
        const TknAmt = ethers.utils.parseUnits(depositValuePWRD.toString(), 'ether');
        const NewAmt = TknAmt;
        
          let transaction = await contract.stakePWRD(NewAmt,30);
          await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
          Toast.fire({icon: 'success',title: 'Successful App Updating'})
        
         
        }
      
        catch (err) {
          console.log(err.message);
          Toast.fire({icon: 'error',title: (err.message)})
        }
      
    }

    async function unstakePWRD() {
   
      
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, signer);
  
        
          let transaction = await contract.unStakePWRD();
          await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
          Toast.fire({icon: 'success',title: 'Successful App Updating'})
        
         
        }
      
        catch (err) {
          console.log(err.message);
          Toast.fire({icon: 'error',title: (err.message)})
        }
      
    }







    async function fetchPWRDearnedData() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(stakingContractAddress, LionMaxx.abi, provider);
        try {
          if(address) {
            const rum = await contract.stakerVaultPWRD(address);
            let rumCount = await(rum);
            let rumTotal = rumCount[4] / 1000000000000000000 .toString()
            let newTotal = rumTotal.toFixed(2)
              
              setPWRDearnedCount(newTotal)
              setTimeout(fetchPWRDearnedData, 10000 );
               
          }}
          catch (err) {
           
          }
        }
    }


   
  
    
   
  
  
   
    
  

    async function fetchTokenData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(tokenContractAddress, LionToken.abi, provider);
          try {
            if(address) {
              const accountBalance = await contract.balanceOf(address);
              
              setBalance((parseInt(accountBalance._hex) / 10 ** 18).toFixed(2));
              setTimeout(fetchTokenData, 10000 );
            }
          }
          catch (err) {
            console.log(err.message);
          }
        }
    }

    
    

  

    return (
        <Box mb="100px">
            <Box >
              <center>
                <Image 
                    src={Logo}
                    alt=''
                    transform="scale(1)"
                    width='150px'
                    transition= "0.3s ease-in-out"
                    _hover={{transform: "scale(1.15)"}}
                    />
                    <br></br>
                    <button
                    className='btnsubmit'
                    type="button"
                    onClick={(e) => {e.preventDefault();
                    window.location.href='https://www.maxxchain.org/';}}> Website</button>
                    <br></br>
                    <button
                    className='btnsubmit'
                    type="button"
                    onClick={(e) => {e.preventDefault();
                    window.location.href='https://t.me/MaxxChain';}}> Telegram</button>
                      <br></br>
                     <button
                    className='btnsubmit'
                    type="button"
                    onClick={(e) => {e.preventDefault();
                    window.location.href='https://bridge.maxxchain.org';}}> Bridge To MaxxChain</button>
                    <br></br>
                   
                  
                </center>
                <br></br>

                


                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="0px">
                   

                   
                      <Card></Card>


                    <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                      <div className='spacer'></div>
                    <Heading className='blue' fontSize={18} bgClip='text' >
                    Stake Maxx, Earn PWR
                    </Heading>
                        <CardHeader className='cardtxt, blue'>
                        <Text>
                        Your Maxx MaxxChain Balance
                        </Text>
                        <Text color="whiteAlpha.800">
                                {balance} 
                            </Text>
                            <br></br>
                        <div className='cardtxt'>
                        <Text color="whiteAlpha.800" fontSize='9px'>
                            All earning are subject to a 10% burn fee.
                        </Text></div>
                        </CardHeader>
                      
                        <div className='spacer'></div>
                        <Text className='blue' color="whiteAlpha.800" fontSize='14px'>
                           Est Earnings To Date:
                        </Text>
                        <Text color="whiteAlpha.800" fontSize='14px'>
                         {earningDayPWRD}
                        </Text>

                        <CardBody>
                            <Text color="whiteAlpha.800">
                                {PWRDcalcTotal} 
                            </Text>
                            <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD'  onClick={() => ClaimPWRD()}>
                        <font><b>CLAIM PWR</b></font>
                        </Button>
                        <br></br>
                        <br></br>
                        <div className='cardtxt'>
                     

                        <Text className='blue' color="whiteAlpha.800">
                            YOUR STAKE
                        </Text></div>
                        <div className='spacer'></div>

                        <Text className='blue' color="whiteAlpha.800" fontSize='12px'>
                           Staked Tokens:
                        </Text>
                        <Text color="whiteAlpha.800" fontSize='12px'>
                          {PWRDtokens} MAXX
                        </Text>
                        <div className='spacer'></div>
                       
                        <Text className='blue' color="whiteAlpha.800" fontSize='12px'>
                           Unlock Time:</Text>
                        <Text color="whiteAlpha.800" fontSize='12px'>
                          {PWRDunlock != 0 ? new Date(PWRDunlock * 1000).toString().slice(0, 21).replace('T', ' ') : 'Not Staked'}
                        </Text>
                   
                        

                        
                        
                        
                        <div className='cardtxt'>
                          <br></br>
                        
                        <Text className='blue' color="whiteAlpha.800">
                            LIFETIME REWARDS
                        </Text>
                       
                            <Text color="whiteAlpha.800">
                                {PWRDEarnedTotal} 
                            </Text>
                           
                            <div className='cardtxt'>
                          <br></br>
                        
                        <Text className='blue' color="whiteAlpha.800">
                            Est Rewards Based
                           
                        </Text>
                        <Text className='blue' color="whiteAlpha.800">
                        
                            On Unstaked Balance
                        </Text></div>
                        <br></br>
                        <div className='cardtxt'>
                        
                        <Text className='blue' color="whiteAlpha.800" fontSize='12px'>
                            30 Day
                        </Text></div>
                    <Text className='pompool1' textAlign="center" fontSize="18px" color="white"  >{calcPWRD}</Text>
                    <br></br>
                    <div className='cardtxt'>
                        
                        <Text className='blue' color="whiteAlpha.800" fontSize='12px'>
                            15 Day
                        </Text></div>
                        <Text className='pompool1' textAlign="center" fontSize="18px" color="white"  >{calcPWRD15}</Text>
                   <br></br>
                            <br></br>
                           
                        <Popup
                   trigger={<button className="lgl" border="2px" fontSize="19px"><b>STAKE/UNSTAKE HERE</b></button>}
                      modal
 nested>
                      {close => (
                      <div className="modal">
                      <div className='headerMD'>
                      <font size="+2" className="blue" ><b>PWR POOL</b></font>
                      </div>
                      <div className='btnsubmit1'>
                                        <p>Early Unstake Fee 15%</p>
                                      </div> <br></br>
                                      <div className='btnsubmit1'>
                                        <p className='blue'>Total $MAXX Staked In Pool</p><p>{PWRDpoolstaked} $MAXX</p>
                                        </div>
                                        <br></br>
                                      <div className='btnsubmit1'>
                                        <p className='blue'>Total Stakers In Pool</p><p>{PWRDpoolstakers}</p>
                                        </div>
                        <button className="close" onClick={close}>
                          
                        </button>
                        
                        
                        <div className="actions">
                        <br></br>
                   
       

                        <label className='btnlabel' for="Cwallet">Stake</label>
                        <br></br>
                        <div className='btnsubmit1'>
                        <p>Your Unstaked Balance</p><p>{balance} $MAXX</p>
                        </div>
                        <br></br>
                       
                     
                     <input type="text" className="form-control" onChange={handleDepositChangePWRD} value={depositValuePWRD} placeholder="ENTER TOKEN AMOUNT"></input> 
                     <br></br>
                     <br></br>
                     <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => approveTokens(depositValuePWRD)}>
                     <font><b>Approve Stake</b></font>
                     </Button>
                     <br></br>
                     <br></br>
            
                     <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => StakePWRD15(depositValuePWRD)}>
                     <font><b>Stake 15 Days</b></font>
                     </Button>
                     <br></br>
                     <br></br>

                     <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => StakePWRD30(depositValuePWRD)}>
                     <font><b>Stake 30 Days</b></font>
                     </Button>
                        
                        <br></br>
                        <br></br>

               
                        <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => unstakePWRD()}>
                        <font><b>Unstake</b></font>
                        </Button>

                        <br></br>
                        <br></br>     
                        <br></br>
                        <br></br>  
                        <Button className='btnsubmit, close' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={close}>
                        <font><b>Close Modal</b></font>
                       
                        </Button>
                         
                        </div>
                        </div>)}         
                   </Popup>


                            <br></br>
                            </div>
                        </CardBody>
                    </Card>
                      

                   

                </Grid>

                <Box textAlign="center" mt="25px" >
                    
                </Box>
                
            </Box>

            <Box>
                <Text textAlign="center" fontSize="30px" color="white" fontWeight="bold" my="35px"></Text>

                <Grid templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)'}} gap="60px">

                        
                        <UnstakedNFT/>

                </Grid>
            </Box>
        </Box>
    );
};

export default Staking;