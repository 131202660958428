import { Flex } from '@chakra-ui/react';
import React from 'react';

const Loader = () => {
    
    return (
        
      <div class="wrapper">
	<svg className='svg1'>
		<text x="50%" y="50%" dy=".35em" text-anchor="middle">
			Maxx Staking
		</text>
	</svg>
</div>
    );
};

export default Loader;