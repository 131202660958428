import React, {useState, useEffect} from 'react';

import { ethers } from 'ethers';
import { useAccount } from 'wagmi';

import LionStaking from '../artifacts/contracts/LionStaking.sol/LionStaking.json';
import LionToken from '../artifacts/contracts/LionToken.sol/LionToken.json';
import Logo from '../Images/logo1.png';
import { Box, Heading, Button, Card, CardBody, Image, CardHeader, Flex, Grid, Text } from '@chakra-ui/react';
import UnstakedNFT from 'Components/UnstakedNFT';
import '../Styles/Home.css'

import Popup from 'reactjs-popup';
import LionNft from '../artifacts/contracts/LionNFT.sol/LionNFT.json';


import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  
 
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})




const tokenContractAddressETH = '0x0bE5DA34c333804b793a3B3B31C3203B2BdD7e94';//pwrrwd
const tokenContractAddressBSC = '0x467833Bad9eB455229E118Af9A12f3C579c7f11C';//pwrrwd



const stakingContractAddressETH = '0x06a1A9D831590Fa7074E16B53B855364D7e0f5bB';
const stakingContractAddressBSC = '0x3324aD43BE713A2EAAffC4a548C4287270BFE18d';


const Staking = () => {

    const { address, isConnecting, isDisconnected} = useAccount();
   
   
    const [depositValuePWRDETH, setDepositValuePWRDETH] = useState();
    const [depositValuePWRDBSC, setDepositValuePWRDBSC] = useState();


    const [depositValueEXPETH, setDepositValueEXPETH] = useState();
    const [depositValueEXPBSC, setDepositValueEXPBSC] = useState();

    



    useEffect(() => {
      // Your data fetching functions here
    
      // Listen for chain changes
      window.ethereum.on('chainChanged', () => {
        updateData();
      });
    
      // Call the updateData function on page load
      updateData();
    }, []);
    
    const updateData = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
    
   
     
   
    }
    
    const handleDepositChangePWRDETH = (e) => {
      setDepositValuePWRDETH(e.target.value)
    }


    const handleDepositChangePWRDBSC = (e) => {
        setDepositValuePWRDBSC(e.target.value)
      }

      const handleDepositChangeEXPBSC = (e) => {
        setDepositValueEXPBSC(e.target.value)
      }

      const handleDepositChangeEXPETH = (e) => {
        setDepositValueEXPETH(e.target.value)
      }
      
    




async function approveTokensETH(depositValuePWRDETH) {
    
  try{
     const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(tokenContractAddressETH, LionToken.abi, signer);
   
    const amountTOApprove = depositValuePWRDETH * 1.05;
    const TknAmt = ethers.utils.parseUnits(amountTOApprove.toString(), 'ether');
    const NewAmt = TknAmt;
    
      let transaction = await contract.approve(stakingContractAddressETH, NewAmt);
      await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
      Toast.fire({icon: 'success',title: 'Successful App Updating'})
     
    }
  
    catch (err) {
      console.log(err.message);
      Toast.fire({icon: 'error',title: (err.message)})
    }
  }



async function approveTokensBSC(depositValuePWRDBSC) {
    
    try{
       const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(tokenContractAddressBSC, LionToken.abi, signer);
      const amountTOApprove = depositValuePWRDBSC * 1.05;
      const TknAmt = ethers.utils.parseUnits(amountTOApprove.toString(), 'ether');
      const NewAmt = TknAmt;
      
        let transaction = await contract.approve(stakingContractAddressBSC, NewAmt);
        await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
        Toast.fire({icon: 'success',title: 'Successful App Updating'})
       
      }
    
      catch (err) {
        console.log(err.message);
        Toast.fire({icon: 'error',title: (err.message)})
      }
    }


    async function SetExpTimeBSC(depositValueEXPBSC) {
    
        try{
           const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(tokenContractAddressBSC, LionNft.abi, signer);
          let time = depositValueEXPBSC.toString();

            let transaction = await contract.SetExpiryOnStake(time);
            await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
            Toast.fire({icon: 'success',title: 'Successful App Updating'})
           
          }
        
          catch (err) {
            console.log(err.message);
            Toast.fire({icon: 'error',title: (err.message)})
          }
        }


        async function SetExpTimeETH(depositValueEXPETH) {
    
            try{
               const provider = new ethers.providers.Web3Provider(window.ethereum);
              const signer = provider.getSigner();
              const contract = new ethers.Contract(tokenContractAddressETH, LionNft.abi, signer);
              let time = depositValueEXPETH.toString();
    
                let transaction = await contract.SetExpiryOnStake(time);
                await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
                Toast.fire({icon: 'success',title: 'Successful App Updating'})
               
              }
            
              catch (err) {
                console.log(err.message);
                Toast.fire({icon: 'error',title: (err.message)})
              }
            }







  







   


   
  
    
   
  
  
   
    
  

 

    
    

  

    return (
        <Box mb="100px">
            <Box >
           

                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="30px">
                   


                    <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                      <div className='spacer'></div>
                        <CardHeader className='cardtxt, blue'>
                        <Text>
                      ETH Pool
                        </Text>
                        </CardHeader>
                    
                    <br></br>
                        <input type="text" className="form-control" onChange={handleDepositChangePWRDETH} value={depositValuePWRDETH} placeholder="ENTER TOKEN AMOUNT"></input> 
                        <br></br>
                    

                        <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => approveTokensETH(depositValuePWRDETH)}>
                     <font><b>Approve Fund Of PWRD</b></font>
                     </Button>

                     <CardHeader className='cardtxt, blue'>
                        <Text>
                      Set Expiry Time DO NOT USE YET
                        </Text>
                        </CardHeader>
                     <input type="text" className="form-control" onChange={handleDepositChangeEXPETH} value={depositValueEXPETH} placeholder="ENTER END TIME"></input> 
                     <br></br>
                     <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => SetExpTimeETH(depositValueEXPETH)}>
                     <font><b>Set End TIme</b></font>
                     </Button>

                    </Card>




                    



                    <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                      <div className='spacer'></div>
                        <CardHeader className='cardtxt, blue'>
                        <Text>
                      BSC Pool
                        </Text>
                        </CardHeader>
                        <br></br>
                  
                        <input type="text" className="form-control" onChange={handleDepositChangePWRDBSC} value={depositValuePWRDBSC} placeholder="ENTER TOKEN AMOUNT"></input> 
                        <br></br>
                       

                        <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => approveTokensBSC(depositValuePWRDBSC)}>
                     <font><b>Approve Fund Of PWRD</b></font>
                     </Button>
                     <br></br>

                     <CardHeader className='cardtxt, blue'>
                        <Text>
                      Set Expiry Time DO NOT USE YET
                        </Text>
                        </CardHeader>


                     <input type="text" className="form-control" onChange={handleDepositChangeEXPBSC} value={depositValueEXPBSC} placeholder="ENTER END TIME"></input> 
                     <br></br>
                     <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => SetExpTimeBSC(depositValueEXPBSC)}>
                     <font><b>Set End TIme</b></font>
                     </Button>
                        

                    </Card>
                      

                   

                </Grid>

                <Box textAlign="center" mt="25px" >
                    
                </Box>
                
            </Box>

           
        </Box>
    );
};

export default Staking;