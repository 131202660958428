import React, {useState, useEffect} from 'react';
import Logo from '../Images/logo1.png';
import { Box, Heading, Button, Card, CardBody, Image, CardHeader, Flex, Grid, Text } from '@chakra-ui/react';
import UnstakedNFT from 'Components/UnstakedNFT';
import '../Styles/Home.css'
import bnb from '../Images/bnb.png'
import eth from '../Images/eth.png'
import maxx from '../Images/Maxx.png'

import { ethers } from 'ethers';
import { useAccount } from 'wagmi';


import { Link } from 'react-router-dom';

const Staking = () => {
  const { address, isConnecting, isDisconnected } = useAccount();
  const [bscConnected, setBscConnected] = useState(false);
  const [ethConnected, setEthConnected] = useState(false);
  const [maxxConnected, setMaxxConnected] = useState(false);

  const connectToBsc = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }], // 56 in hex
      });
      setBscConnected(true);
    } catch (error) {
      console.error(error);
    }
  };


  const connectToEth = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }], // 1 in hex
      });
      setEthConnected(true);
    } catch (error) {
      console.error(error);
    }
  };

  const connectToMaxx = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x27d1' }], // 10201 in hex
      });
      setMaxxConnected(true);
    } catch (error) {
      console.error(error);
    }
  };

 

  return (
    <Box mb="100px">
      <Box>
        <center>
          <Image 
            src={Logo}
            alt=''
            transform="scale(1)"
            width='150px'
            transition= "0.3s ease-in-out"
            _hover={{transform: "scale(1.15)"}}
          />
          <br />
          <button
            className='btnsubmit'
            type="button"
            onClick={(e) => {
              e.preventDefault();
              window.location.href='https://maxxchain.org';
            }}>
            Website
          </button>
          <br />
          <button
            className='btnsubmit'
            type="button"
            onClick={(e) => {
              e.preventDefault();
              window.location.href='https://t.me/MaxxChain';
            }}>
            Telegram
          </button>
          <br />
          <Heading className='headertxt' bgClip='text' />
          <br />
          <Heading className='headertxt' bgClip='text'>
            Choose Your Pool.
            <Text color="whiteAlpha.800" />
          </Heading>
          <div className='spacer'></div>
          <Text className='blue' color="whiteAlpha.800">
          Next Staking Pool Update Between 16:00UTC & 23:59 UTC On October 6th 2023
                        </Text>
                        <div className='spacer'></div>
        </center>
        <br />
        <br />
        <br />
        <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="60px">
          <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px" fontSize="25px" fontWeight="bold">
            <Link to="/BSC" onClick={connectToBsc} disabled={bscConnected}>
              <CardHeader className='cardtxt'>
                <Image
                  className='bnbpool'
                  src={bnb}
                  alt=''
                  transform="scale(1)"
                  width='150px'
                  height='150px'
                  transition= "0.3s ease-in-out"
                  _hover={{transform: "scale(1.15)"}}
                />
                <br />
                Binance Smart Chain
              </CardHeader>
            </Link>
          </Card>
          <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px" fontSize="25px" fontWeight="bold">
            <Link to="/MAXX" onClick={connectToMaxx} disabled={maxxConnected}>
              <CardHeader className='cardtxt'>
                <Image
                  className='bnbpool'
                  src={maxx}
                  alt=''
                  transform="scale(1)"
                  width='150px'
                  height='150px'
                  transition= "0.3s ease-in-out"
                  _hover={{transform: "scale(1.15)"}}
                />
                <br />
                MaxxChain
              </CardHeader>
            </Link>
          </Card>
          <Card textAlign="center" border="2px" borderColor='blue.
          50' borderRadius="30px" fontSize="25px" fontWeight="bold">
          <Link to="/ETH"  onClick={connectToEth} disabled={ethConnected}>
          <CardHeader className='cardtxt'>
          <Image
          className='bnbpool'
          src={eth}
          alt=''
          transform="scale(1)"
          width='100px'
          height='150px'
          transition= "0.3s ease-in-out"
          _hover={{transform: "scale(1.15)"}}
          />
          <br />
          Ethereum
          </CardHeader>
          </Link>
          </Card>
          </Grid>
          <Box textAlign="center" mt="25px" />
          </Box>
          <Box>
          <Text textAlign="center" fontSize="30px" color="white" fontWeight="bold" my="35px"></Text>
          <Grid templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)'}} gap="60px">
          <UnstakedNFT />
          </Grid>
          <br>
          </br>
          <br></br>
          </Box>
          </Box>
          
          );
          };
          
          export default Staking;